import * as React from 'react';
import {graphql} from 'gatsby';

import Layout from '../components/layout';
import SpeakersSection from '../components/sections/speakers-overview-section';
import SponsorsSection from '../components/sections/sponsors-section-old';
import CONTENT from '../components/content';
import EmbededVideoSection from '../components/sections/embed-video-section';

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(filter: {fileAbsolutePath: {regex: "/speakers/2023/"}}) {
      edges {
        node {
          id
          body
          slug
          frontmatter {
            fullName
            title
            company
            talkTitle
            website
            github
            linkedin
            twitter
            imageSrc {
              childImageSharp {
                gatsbyImageData(height: 340, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
              }
            }
          }
        }
      }
    }
  }
`;

const Page2023 = ({data}: {data: any}) => {
  const speakers = data.allMdx.edges;

  return (
    <Layout>
      {/* <EmbededVideoSection
        url={'https://www.youtube.com/embed/kgsHPw3hriU'}
        title={'Official 2022 after-movie'}
      /> */}
      <SpeakersSection speakers={speakers} />
      <SponsorsSection sponsors={CONTENT.SPONSORS_OLD} />
    </Layout>
  );
};
export default Page2023;
